import { Badge, Button, Divider, Group, Image, Stack, Text, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { CheckCircleFill, CheckCircleOutline } from 'antd-mobile-icons'
import FloatingButton from '../components/FloatingButton'
import RightFloating from '../components/RightFloating'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { payment } from '../api/orderService'
import { getDictByKey } from '../api/dictionaryService'

interface ChannelOption {
  key: string
  title: string
  image: string
  router: string
  desc?: string
}

export default function Payment() {
  const navigate = useNavigate()
  const [time, setTime] = useState('5:00')
  const [selected, setSelected] = useState<ChannelOption>()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId') || ''
  const templateName = searchParams.get('templateName') || ''
  const templatePrice = searchParams.get('templatePrice') || ''
  const [channel, setChannel] = useState<ChannelOption[]>([])

  const isEnvWeixin = /MicroMessenger/i.test(window.navigator.userAgent)
  const isEnvAlipay = /AlipayClient/i.test(window.navigator.userAgent)

  useEffect(() => {
    getDictByKey('key_pay_channel')
      .then((data) => {
        let channels = JSON.parse(data)
        if (isEnvWeixin) {
          channels = channels.filter((item: ChannelOption) => item.key !== 'alipay')
        }
        if (isEnvAlipay) {
          channels = channels.filter((item: ChannelOption) => item.key !== 'fuiou')
        }
        setChannel(channels)
        setSelected(channels[0])
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      const [minutes, seconds] = time.split(':').map(Number)
      if (minutes === 0 && seconds === 0) {
        clearInterval(timer)
      } else if (seconds === 0) {
        setTime(`${minutes - 1}:59`)
      } else {
        setTime(`${minutes}:${seconds < 11 ? `0${seconds - 1}` : seconds - 1}`)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [time])

  const doPayment = () => {
    setLoading(true)
    payment(orderId, selected?.router!)
      .then((res) => {
        setLoading(false)
        const div = document.createElement('div')
        div.innerHTML = res
        document.body.appendChild(div)
        const form = div.querySelector('form')
        form?.submit()
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const CheckListItem = ({
    children,
    checked,
    onChange,
  }: {
    children: React.ReactNode
    checked: boolean
    onChange: () => void
  }) => {
    return (
      <Group justify="space-between" onClick={onChange}>
        {children}
        {checked ? (
          <CheckCircleFill color={'#3A55DD'} fontSize={26} />
        ) : (
          <CheckCircleOutline color={'#3A55DD'} fontSize={26} />
        )}
      </Group>
    )
  }

  return (
    <Stack>
      <Stack align={'center'} p={30}>
        <Title order={4}>{templateName}</Title>
        <Title>{`¥${templatePrice}`}</Title>
        <Stack gap={6} align={'center'}>
          <Text c={'gray'} mt={10}>{`支付剩余时间 ${time}`}</Text>
          <Text c={'gray'}>{`订单编号 ${orderId}`}</Text>
        </Stack>
      </Stack>
      <Divider size={'lg'} />
      <Stack px={20} py={10}>
        <Title order={5}>选择支付方式</Title>
        <Stack mt={10} gap={24}>
          {channel.map((item) => (
            <CheckListItem
              key={item.key}
              checked={selected?.key === item.key}
              children={
                <Group>
                  <Image h={26} w="auto" src={item.image} />
                  <Text>{item.title}</Text>
                  {item.desc && (
                    <Badge variant="outline" color="#3A55DD" size="lg" radius="sm">
                      {item.desc}
                    </Badge>
                  )}
                </Group>
              }
              onChange={() => setSelected(item)}
            />
          ))}
        </Stack>
      </Stack>
      <Group justify="space-between" mt="md" px={20}>
        <Button
          onClick={() => {
            doPayment()
          }}
          fullWidth
          radius="md"
          size={'md'}
          loading={loading}
          styles={{
            root: {
              backgroundColor: '#3A55DD',
            },
          }}
        >
          <Text>立即支付</Text>
        </Button>
      </Group>
      <RightFloating>
        <Stack>
          <FloatingButton
            color={'green'}
            label={'历史报告'}
            onClick={() => {
              navigate('/orders')
            }}
          />
        </Stack>
      </RightFloating>
    </Stack>
  )
}
