import http, { ResponseData } from './httpService'
import {
  CreateOrderRequest,
  CreateOrderResponse,
  OrderInterface,
  TemplateMerchantInterface,
} from '../typings/orderTypes'
import { PageInterface } from '../typings/types'

export const getOrders = async (): Promise<OrderInterface[]> => {
  const { data } = await http.post<ResponseData<PageInterface<OrderInterface>>>('/fe/user/center/order/user/list', {
    current: 1,
    pageSize: 100,
  })
  return data.data.records
}

export const facade = async (linkId: String): Promise<TemplateMerchantInterface> => {
  const { data } = await http.get<ResponseData<TemplateMerchantInterface>>('/fe/user/center/order/facade/' + linkId)
  return data.data
}

export const create = async (param: CreateOrderRequest): Promise<CreateOrderResponse> => {
  const { data } = await http.post<ResponseData<CreateOrderResponse>>('/fe/user/center/order/create', param)
  return data.data
}

export const payment = async (orderId: string, router: string): Promise<string> => {
  const { data } = await http.post<ResponseData<string>>(
    '/fe/user/center/payment/' + orderId,
    {},
    {
      headers: {
        'isaac.payment.router': router,
      },
    }
  )
  return data.data
}

export const report = async (orderId: String): Promise<ResponseData<Map<String, any>>> => {
  const { data } = await http.get<ResponseData<Map<String, any>>>('/fe/user/center/report/' + orderId)
  return data
}
