import React, { useLayoutEffect, useState } from 'react'
import { Box, Image, Overlay } from '@mantine/core'

export default function InterceptorWrapper({
  children,
  isOpen = true,
}: {
  children: React.ReactNode
  isOpen?: boolean
}) {
  const [opened, setOpened] = useState(false)

  useLayoutEffect(() => {
    const isEnvWeixin = /MicroMessenger/i.test(window.navigator.userAgent)
    const isEnvAlipay = /AlipayClient/i.test(window.navigator.userAgent)
    if (isEnvWeixin) {
      setOpened(true)
    }
  }, [])

  return (
    <>
      {!opened || !isOpen ? (
        children
      ) : (
        <Box pos="relative">
          {opened && (
            <Overlay color="#000" backgroundOpacity={0.65}>
              <Image src="https://file.sjcyx.com/wx_alert.png" />
            </Overlay>
          )}
          {children}
        </Box>
      )}
    </>
  )
}
